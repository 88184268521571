import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import succeed from '../views/succeed'
import agreement from '../views/agreement.vue'
import privacy from '../views/privacy.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path:'/succeed',
    name:'succeed',
    component:succeed
  },
  {
    path:'/agreement',
    name:'agreement',
    component:agreement
  },
  {
    path:'/privacy',
    name:'privacy',
    component:privacy
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
