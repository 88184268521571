<template>
  <div class="home">
    <!--    大屏-->
    <div class="logo">
      <img class="logotitle" src="https://img.hsx777.cn/mjiihee/logotitle.png">
      <p>专业改车配件采购平台</p>
      <p>在线采购 | 实时库存 | 极速发货 | 正品保证</p>
      <div class="logo-button">
        <a v-if="appCode == 'CAR_ADMIN_IOS' && !isWeixin" :href="IOS" target="_blank" style="text-decoration: none;color: #fff;">
          <button>
              <img src="https://img.hsx777.cn/mjiihee/apply-for.png">
              <span>立即下载</span>
          </button>
        </a>
        <a v-if="appCode == 'CAR_ADMIN_ADR' && !isWeixin" :href="ADR" target="_blank" style="text-decoration: none;color: #fff;">
          <button>
            <img src="https://img.hsx777.cn/mjiihee/apply-for.png">
            <span>立即下载</span>
          </button>
        </a>
        <a v-if="isWeixin" href="https://a.app.qq.com/o/simple.jsp?pkgname=com.hsx.jiiheeapp" target="_blank" style="text-decoration: none;color: #fff;">
          <button>
            <img src="https://img.hsx777.cn/mjiihee/apply-for.png">
            <span>立即下载</span>
          </button>
        </a>
<!--        <button @click="log">-->
<!--          <span>登录网页版</span>-->
<!--          <img src="https://img.hsx777.cn/mjiihee/right_arrow.png">-->
<!--        </button>-->
<!--        <div class="hint">仅支持安卓系统用户</div>-->
      </div>
      <img class="controlplatform" src="https://img.hsx777.cn/mjiihee/controlplatform.png">
    </div>
    <!--    供应链-->
    <div class="supply">
      <img src="https://img.hsx777.cn/mjiihee/reliable.png">
      <img src="https://img.hsx777.cn/mjiihee/warehouse.png">
      <img src="https://img.hsx777.cn/mjiihee/subscript.png">
      <div class="supply-imgs">
        <div>
          <div>
            <img src="https://img.hsx777.cn/mjiihee/handshake.png">
          </div>
          <div>
            <p>直营供应链</p>
            <p>线上报价下单，简单易用，零成本上手。</p>
          </div>
        </div>
        <div>
          <div>
            <img src="https://img.hsx777.cn/mjiihee/salable-product.png">
          </div>
          <div>
            <p>正品保证</p>
            <p>品牌厂家授权直供，100%品质保障。</p>
          </div>
        </div>
        <div>
          <div>
            <img src="https://img.hsx777.cn/mjiihee/quickness.png">
          </div>
          <div>
            <p>急速发货</p>
            <p>多仓直发，24小时极速发货，物流信息实时可查。</p>
          </div>
        </div>
      </div>
    </div>
    <!--    配件-->
    <div class="accessories">
      <img src="https://img.hsx777.cn/mjiihee/good-stuff.png">
      <div class="swiper-container" id="swiper1" setWrapperSize autoplay="true" direction="horizontal" >
        <div class="swiper-wrapper" >
          <div class="swiper-slide" data-swiper-autoplay="2000">
            <img src="https://img.hsx777.cn/mjiihee/BBS-CHRII.png">
            <p>BBS-CHR II</p>
            <p>暂无报价</p>
          </div>
          <div class="swiper-slide"  data-swiper-autoplay="2000">
            <img src="https://img.hsx777.cn/mjiihee/BBS-SR.png">
            <p>BBS-SR</p>
            <p>暂无报价</p>
          </div>
          <div class="swiper-slide"  data-swiper-autoplay="2000">
            <img src="https://img.hsx777.cn/mjiihee/BBS-SuperRS.png">
            <p>BBS-Super RS</p>
            <p>￥45000起</p>
          </div>
          <div  class="swiper-slide" data-swiper-autoplay="2000">
            <img src="https://img.hsx777.cn/mjiihee/brembo.png">
            <p>brembo-高级陶瓷制动片</p>
            <p>暂无报价</p>
          </div>
          <div  class="swiper-slide"  data-swiper-autoplay="2000">
            <img src="https://img.hsx777.cn/mjiihee/Brembo-Xtra.png">
            <p>Brembo-Xtra</p>
            <p>暂无报价</p>
          </div>
          <div  class="swiper-slide" data-swiper-autoplay="2000">
            <img src="https://img.hsx777.cn/mjiihee/ENDLESS_EC670.png">
            <p>ENDLESS_EC670</p>
            <p>￥13000起</p>
          </div>
          <div  class="swiper-slide" data-swiper-autoplay="2000">
            <img src="https://img.hsx777.cn/mjiihee/KW_Clubsport_3way.png">
            <p>KW_Clubsport_3way</p>
            <p>￥30000起</p>
          </div>
          <div class="swiper-slide" data-swiper-autoplay="2000">
            <img src="https://img.hsx777.cn/mjiihee/KW_Variant1.png">
            <p>KW_Variant 1</p>
            <p>￥13000起</p>
          </div>
          <div class="swiper-slide" data-swiper-autoplay="2000">
            <img src="https://img.hsx777.cn/mjiihee/Repose.png">
            <p>Repose-四出中尾段智能阀门排气</p>
            <p>暂无报价</p>
          </div>
          <div class="swiper-slide" data-swiper-autoplay="2000">
            <img src="https://img.hsx777.cn/mjiihee/ROTIFORM-BLQ.png">
            <p>ROTIFORM-BLQ</p>
            <p>￥2250起</p>
          </div>
          <div class="swiper-slide" data-swiper-autoplay="2000">
            <img src="https://img.hsx777.cn/mjiihee/ROTIFORM-DTM.png">
            <p>ROTIFORM-DTM</p>
            <p>￥2130起</p>
          </div>
          <div class="swiper-slide" data-swiper-autoplay="2000" >
            <img src="https://img.hsx777.cn/mjiihee/ROTIFORM-OZR.png">
            <p>ROTIFORM-OZR</p>
            <p>￥2700起</p>
          </div>
        </div>
      </div>
    </div>
    <!--    合作伙伴-->
    <div class="partner" >
      <img src="https://img.hsx777.cn/mjiihee/cooperation.png">
      <div class="swiper-container" id="swiper2" setWrapperSize :autoplay="1000" direction="horizontal" >
        <div class="swiper-wrapper" id="a01">
          <div class="swiper-slide"><img src="https://img.hsx777.cn/mjiihee/1BBS.png"></div>
          <div class="swiper-slide"><img src="https://img.hsx777.cn/mjiihee/2ENDLESS.png"></div>
          <div class="swiper-slide"><img src="https://img.hsx777.cn/mjiihee/3KW.png"></div>
          <div class="swiper-slide"><img src="https://img.hsx777.cn/mjiihee/4WORK.png"></div>
          <div class="swiper-slide"><img src="https://img.hsx777.cn/mjiihee/5Brembo.png"></div>
          <div class="swiper-slide"><img src="https://img.hsx777.cn/mjiihee/6APRACING.png"></div>
          <div class="swiper-slide"><img src="https://img.hsx777.cn/mjiihee/7Velocita.png"></div>
          <div class="swiper-slide"><img src="https://img.hsx777.cn/mjiihee/8Repose.png"></div>
        </div>
      </div>
      <p>
        更多品牌正在入驻
      </p>
    </div>
    <!--    申请-->
    <div class="apply-for">
      <p>免费使用</p>
      <p>仅需一步，开启线上采购模式</p>
      <div class="apply-for-input">
        <input placeholder="请输入你的手机号码" v-model="mobile" />
        <button @click="decide"><span>立即申请</span></button>
      </div>
    </div>
    <!--    介绍-->
    <div class="introduce">
      <div><img src="https://img.hsx777.cn/mjiihee/logotitle.png"></div>
      <div>一个专注于改装车后市场服务升级的团队，具备丰富的汽车改装行业背景，对行业痛点、优化方向及实现路径具有深刻认识。通过打造一站式采购、销售、技术信息平台重塑汽车后市场服务及配件行业业态；通过输出专业的汽车改装文化以及研发汽车配件、房车露营衍生品扩大品牌影响力。</div>
      <div class="introduce-classification">
        <img src="https://img.hsx777.cn/mjiihee/phone.png">
        <span>陈先生-17850159422</span>
      </div>
      <div class="introduce-classification">
        <img src="https://img.hsx777.cn/mjiihee/E-mail.png">
        <span>chenzhihe@jiihee.com</span>
      </div>
      <div class="introduce-classification">
        <img src="https://img.hsx777.cn/mjiihee/site.png">
        <span>厦门市思明区软件园二期望海路23号楼之一</span>
      </div>
      <div class="QR-code-box">
        <img src="../../src/assets/QR-code1.png">
        <span>微信扫码添加</span>
      </div>
      <p>版权：厦门厚思想工业设计有限公司</p>
      <p>备案：闽ICP备2021009637号-2</p>
    </div>
    <PullDown class="pull-down" v-if="scrollTop" @setShow = "setShow" :CODE="appCode == 'CAR_ADMIN_IOS'? IOS : ADR" :isWeixin="isWeixin"></PullDown>
    <div class="BG" v-if="show">
<!--      wx-down-tips.png-->
<!--      wx-down-btn.png-->
      <div class="text">
        <img src="../assets/wx-down-tips.png">
        <img @click="close" src="../assets/wx-down-btn.png">
      </div>
    </div>
  </div>
</template>

<script>
import PullDown from '@/components/pull-down.vue'
import { Dialog } from 'vant';
import {saveBusinessChance ,queryVersionUpdateUrl} from "@/service/mobile";
import Swiper from 'swiper';
export default {
  name: 'Home',
  components : {
    PullDown,
  },
  data(){
    return{
      img:require('@/assets/logo.png'),
      active:true,
      scrollTop:false,
      mobile:null,
      appCode:'',
      list:[],
      ADR:'',
      IOS:'',
      isWeixin:false,
      show:false,
    }
  },
  created() {
    let _this = this;
    window.onscroll = function () {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 300){
        _this.scrollTop = true;
      }else{
        _this.scrollTop = false;
      }
    }
  },
  mounted(){
    this.setSwiper();
    var u = navigator.userAgent;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if(isAndroid){
      this.appCode = 'CAR_ADMIN_ADR'
    }
    if(isiOS){
      this.appCode = 'CAR_ADMIN_IOS'
    }

    var ua = navigator.userAgent.toLowerCase();
    this.isWeixin = ua.indexOf('micromessenger') != -1;
    this.queryVersionUpdateUrl()
    //this.queryVersionControl()
  },
  methods:{
    close(){
      this.show =false;
    },
    setShow(val){
      this.show =val;
    },
    target(){
      this.show = true;
    },
    async queryVersionUpdateUrl(){
      this.list = await queryVersionUpdateUrl()
      if (this.appCode == 'CAR_ADMIN_IOS'){
        this.IOS = this.list.data.IOS
      }else{
        this.ADR = this.list.data.ADR
      }
    },
    /*async queryVersionControl(){
      this.list = await  queryVersionControl();

    },*/
    log(){
      if (window.location.origin == 'https://3030.hsx777.cn/'){
        window.location.href = 'https://3031.hsx777.cn/';
      }else if(window.location.origin == 'https://m.jiihee.com'){
        window.location.href = 'https://mall.jiihee.com';
      }
    },
    setSwiper(){
      new Swiper('#swiper1', {
        slidesPerView: 3.0,
        loop:true,
        disableOnInteraction:false,
        loopAdditionalSlides:1,
        waitForTransition:false,
        speed:500,//匀速时间
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
      new Swiper('#swiper2', {
        slidesPerView: 3.9,
        loop:true,
        disableOnInteraction:false,
        loopAdditionalSlides:1,
        waitForTransition:false,
        speed:4000,//匀速时间
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
    },
    async decide(){
      let num = this.mobile;
      let val = /^1[0-9]{10}$/;
      if (val.test(num)){
        let parmas={
          telephone:this.mobile,
          source:'移动端官网'
        }
        let res =  await saveBusinessChance(parmas);
        if (res.code == 0 ){
          this.$router.push({path:'succeed'})
        }else{
          Dialog({ message: `${res.data.message}` });
        }
      }else{
        Dialog.alert({
          message: '手机号格式错误,请重新输入',
        }).then(() => {
          // on close
        });
      }
    }
  }
}
</script>

<style lang="less" >
.my-swipe{
  width: 100%;
  &>div{
    width: 100%;
    &>div{
      width: 200px;
    }
  }
}
.BG{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.6);
  color: #FFFFFF;
  z-index: 9999999;
  .text{
    position: absolute;
    right: 10%;
    top: 5%;
    img:nth-of-type(1){
      width: 300px;
      display: block;
    }
    img:nth-of-type(2){
      width: 170px;
      margin-top: 10%;
      margin-left: 20%;
    }
  }
}
.logo{
  width: 100%;
  height: 1110px;
  padding-left:88px;
  padding-right: 90px;
  box-sizing: border-box;
  background: url("https://img.hsx777.cn/mjiihee/background.png") no-repeat;
  background-size: 100% 100%;
  .logotitle{
    width: 346px;
    height: 52px;
    margin: 166px 0 94px;
  }
  p:nth-of-type(1){
    height: 80px;
    color: #09172F;
    font-weight: 600;
    font-size: 46px;
    margin-bottom: 20px;
  }
  p:nth-of-type(2){
    font-weight: 400;
    color: #8E8E8E;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 40px;
  }
  .logo-button{
    display: flex;
    position: relative;
    button{
      display:inline-block;
      width: 220px;
      height: 64px;
      line-height: 64px;
      border-radius: 45px;
      background: #527E69;
      border: 0;
      color: #FFFFFF;
      text-align: center;
      font-size: 26px;
      img{
        width: 26px;
        vertical-align: middle;
        align-items: center;
      }
      span{
        align-items: center;
        display: inline-block;
        vertical-align: middle;
      }
    }
    a{
      button{
        margin-right: 24px;
        img{
          margin-left: 0;
          margin-right: 18px;
        }
      }
    }
    button{
      img{
        margin-left: 18px;
      }
    }
    .hint{
      position: absolute;
      left: 2%;
      top: 110%;
      color: #8E8E8E;
    }
  }
  .controlplatform{
    width: 598px;
    height: 292px;
    margin-top: 100px;
  }
}
.supply{
  text-align: center;
  position: relative;
  margin-bottom: 106px;
  img:nth-of-type(1){
    width: 486px;
    height: 118px;
    margin: 50px 0 20px 0;
  }
  img:nth-of-type(2){
    width: 652px;
    height: 220px;
    margin-bottom: 98px;
  }
  img:nth-of-type(3){
    width: 66px;
    height: 68px;
    position: absolute;
    top: calc(118px + 220px + 35px);
    right: 108px;
  }
  .supply-imgs{
    padding-left: 74px;
    div:last-child{
      margin-bottom: 0;
    }
    &>div{
      display: flex;
      text-align: left;
      vertical-align: middle;
      margin-bottom: 72px;
      img{
        width: 72px;
        height: 72px;
        margin: 0;
        margin-right: 32px;
        vertical-align: middle;
      }
      p:nth-of-type(1){
        color: #09172F;
        font-size: 32px;
        font-weight: 500;
        height: 44px;
        line-height: 44px;
      }
      p:nth-of-type(2){
        margin-top: 10px;
        color: #495D86;
        font-weight: 400;
        font-size: 26px;
      }
    }
  }
}
.accessories{
  text-align: center;
  box-sizing: border-box;
  img:nth-of-type(1){
    width: 460px;
    height: 118px;
    margin-bottom: 24px;
  }
  .swiper-wrapper{
    display: flex;
    margin-bottom: 72px;
    -webkit-transition-timing-function: linear; /*之前是ease-out*/
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    .swiper-slide{
      width: 214px !important;
      padding:16px;
      text-align: center;
      margin:2px;
      img{
        width: 200px;
        height: 201px;
        margin: 0;
      }
      p:nth-of-type(1){
        width: 200px;
        height: 68px;
        font-size: 24px;
        font-weight: 500;
        color: #09172F;
        word-wrap: break-word;
      }
      p:nth-of-type(2){
        height: 34px;
        font-size: 24px;
        font-weight: 600;
        color: #66A386;
      }
    }
  }
}
.partner{
  padding-bottom: 34px;
  text-align: center;
  background: rgb(248,251,253);
  padding: 0 10px;
  .swiper-wrapper{
    -webkit-transition-timing-function: linear; /*之前是ease-out*/
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
  }
  img:nth-of-type(1){
    width: 460px;
    height: 118px;
    margin-bottom: 38px;
  }
  .swiper-slide{
    display: flex;
    margin-bottom: 38px;
    overflow: hidden;
    img{
      width: 160px;
      height: 160px;
      margin: 0 10px;
    }
  }
  p{
    height: 32px;
    font-size: 22px;
    font-weight: 400;
    color: #AAB6C8;
    line-height: 32px;
    padding-bottom: 58px;
  }
}
.apply-for{
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
  background: url("https://img.hsx777.cn/mjiihee/bg.png") no-repeat;
  background-size: 100% 100%;
  height: 498px;
  p:first-child{
    padding-top: 106px;
    height: 52px;
    font-size: 38px;
    font-weight: 600;
    color: #09172F;
    line-height: 52px;
  }
  p:nth-of-type(2){
    margin-top: 6px;
    height: 36px;
    font-size: 26px;
    font-weight: 400;
    color: #516C95;
    line-height: 36px;
    margin-bottom: 46px;
  }
  .apply-for-input{
    width: 600px;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.11);
    border-radius: 40px;
    border: 2px solid #527E69;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 2px;
    align-items: center;
    input{
      border: 0;
      width: calc(600px - 170px);
      border-radius: 40px;
      outline: none;
      padding-left: 34px;
      align-items: center;
      font-size: 26px;
    }
    button{
      border: 0;
      width: 170px;
      height: 68px;
      background: #527E69;
      border-radius: 40px;
      align-items: center;
      margin: 6px 6px 6px 0;
      color: #fff;
    }
  }
}
.introduce{
  padding-top: 120px;
  margin-bottom: 96px;
  text-align: left;
  img{
    width: 414px;
    height: 62px;
    margin-bottom: 46px;
  }
  div:nth-of-type(1){
    width: 520px;
    margin: 0 auto;
  }
  div:nth-of-type(2){
    width: 520px;
    height: 240px;
    text-align: left;
    font-size: 26px;
    font-weight: 400;
    color: #9BA6BA;
    line-height: 40px;
    margin: 0 auto;
    margin-bottom: 62px;
  }
  .introduce-classification{
    width: 520px;
    text-align: left;
    vertical-align: middle;
    margin-bottom: 24px;
    padding-left: 118px;
    display: flex;
    img{
      width: 24px;
      height: 24px;
      margin: 0;
      margin-right: 24px;
      vertical-align: middle;
      margin-top: 5px;
    }
    span{
      height: 32px;
      font-size: 28px;
      font-weight: 400;
      color: #9BA6BA;
      line-height: 32px;
      vertical-align: middle;
      display: inline-block;
    }
  }
  .QR-code-box{
    width: 242px;
    height: 264px;
    background: #FFFFFF;
    box-sizing: border-box;
    text-align: center;
    margin: 0 auto;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.11);
    border-radius: 16px;
    padding: 20px 20px 0 22px;
    margin-top: 80px;
    margin-bottom: 30px;
    img{
      width: 200px;
      height: 200px;
      margin: 0;
    }
    span{
      height: 28px;
      font-size: 22px;
      font-weight: 400;
      color: #78859D;
      line-height: 32px;
    }
  }

  p{
    margin: 0 auto;
    text-align: center;
    width: 520px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9BA6BA;
    line-height: 34px;
  }
}
</style>
